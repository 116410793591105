.diamond-animation-loader{
  width: 180px;
}

.pricing-page {
  background: #FBFCFF;
}

.top-slider-grid {
  /* padding-top: 10px; */
  display: none;
}

.top-slide {
  opacity: 0.6;
  margin: 0px 10px;
}

.top-slider-grid .top-slide:first-child {
  margin-left: 0;
}

.top-slider-grid .slick-active .top-slide {
  opacity: 1;
}

.up-to-text {
  font-size: 10px;
  font-weight: 600;
  margin: 0px;
}

.top-slide h3 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #0d1949;
  margin: 0;
}

.top-slide .btn {
  background: #ffffff;
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 36px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #182960;
  transition: all 0.5s;
}

.top-slide .btn:hover {
  background: #ffffff;
  color: #182960;
  border: none;
  transition: all 0.5s;
}

.top-slide .btn:first-child:active,
.top-slide:not(.btn-check)+.btn:active,
.top-slide .btn:active {
  background: #ffffff;
  border: none;
  box-shadow: none;
  color: #182960;
}

.top-slider-grid .slick-active .top-slide .btn {
  border-bottom: 4px solid #182960;
}

.top-slider-grid .slick-dots li button:before {
  color: #ffffff;
  opacity: 0.4;
  font-size: 9.5px;
}

.slick-dots .slider-dot-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.slick-dots .slider-dot-ul li {
  margin: 0;
  width: fit-content;
  height: fit-content;
}

.top-slider-grid .slick-dots {
  bottom: -35px;
  display: flex !important;
  justify-content: center;
  gap: 2px;
  transform: translateY(4px);
  opacity: 0;
  z-index: 99;
}

.top-slider-grid .slick-dots li {
  margin: 0;
}

.top-slider-grid .slick-dots li.slick-active button:before {
  background: #182960;
  border-radius: 100%;
  border: 3px solid #ffffff;
  color: #182960;
}

.pricing-slider .slick-prev,
.pricing-slider .slick-next {
  display: none !important;
}

.pricing-slider .slick-list {
  /* padding: 0 30px !important; */
  transition: all 0.3s;
}

.bottom-slider-grid {
  padding-top: 14px;
  height: calc(100vh - 86px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom-slide {
  margin: 0px 13px;
}

.slide-count-grid {
  display: flex;
  justify-content: center;
  /* padding-block: 10px 20px; */
  align-items: center;
  flex-direction: column;
  /* gap: 10px; */
}

.slide-count-grid span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #182960;
}

/* .bottom-slider-grid .bottom-slide:first-child {
  margin-left: 11px;
} */
.pricing-inner-layout {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.layout-for-select {
  background-color: #0d1949;

}

.pricing-grade {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 37px;
  z-index: 9999;
}

.dark-bg-grid {
  background: #181f37;
}

.advance-idu-footer-section{
   display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 304px;
  min-height: 180px;
  gap: 40px;
  margin: auto;
}


.advance-pricing-btn{
  display: flex;
  justify-content: flex-end;
  max-width: 400px;
  margin: auto;
  margin-top: 25px;

}

.advance-pricing-btn .pricing-userlogin-content-btn {
  width: 100%;
  max-width: 304px;
}

.advance-pricing-btn .btn {
  border-radius: 0px;
  height: 100%;
}

@media screen and (min-width:493px) {
  .pricing-grade {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    /* min-height: 492px; */
    padding-top: 37px;
    z-index: 9999;
    min-height: 480px;

  }
}

.pricing-grade .btn {
  background: none;
  border: none;
  padding: 0;
}

.pricing-grade .btn:hover,
.pricing-grade .btn:focus,
.pricing-grade .btn:focus-visible,
.pricing-grade .btn:active,
.pricing-grade .btn:first-child:active {
  background: none;
  border: none;
  box-shadow: none;
}

.pricing-grade h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #182960;
  margin: 0;
  padding: 62px 0px;
}


.select-grid {
  width: 100%;
  padding: 0px 48px;
}

.fcrf-select__control {
  height: auto;
  min-height: 62px !important;
  background-color: transparent !important;
  border: 2px solid #6a77a2 !important;
  box-shadow: none !important;
  width: 100%;
  text-align: center;
  border-radius: 0 !important;
}

.fcrf-select__control:hover {
  border: 2px solid #6a77a2 !important;
}

.fcrf-select__indicator-separator {
  display: none;
}

.fcrf-select__menu {
  border: 1px solid #ffffff !important;
}

.fcrf-select__menu-list {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  border: 1px solid #ffffff !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #181f37 !important;
}

.fcrf-select__option {
  background: transparent !important;
  border-bottom: 1px solid #e5e7ef;
}

.fcrf-select__option:last-child {
  border: none;
}

.fcrf-select__value-container .fcrf-select__input input {
  min-width: 70px;
  padding-left: 10px !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #6e6893 !important;
  height: 30px;
}

.fcrf-select__menu .fcrf-select__option {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #181f37 !important;
  padding: 10px 31px;
}

/* .tab-btns-wrapper {
  max-width: 188px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border: 2px solid rgba(216, 165, 80, 0.4);
  border-radius: 50px;
  padding: 5px;
}

.tab-btn {
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: lowercase;
  color: #b18503;
  transition: 1s ease-in-out;
  cursor: pointer;
  background: #ffffff;
  border-radius: 50px;
}

.tab-btn.active {
  background: #b18503;
  border-radius: 50px;
  color: #ffffff;
  transition: all 0.5s;
} */

.next-btn-grid {
  display: flex;
  justify-content: center;
  padding: 34px 32px 20px 32px;
}

.next-btn.btn,
.next-btn {
  width: 100%;
  background: #b18503;
  border-radius: 0px;
  height: 54px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #ffffff;
}

.next-btn.btn img,
.next-btn img {
  margin-left: 8px;
}

.next-btn.disabled,
.next-btn:disabled,
fieldset:disabled .next-btn,
.next-btn.btn.disabled,
.next-btn.btn:disabled,
fieldset:disabled .next-btn.btn {
  opacity: 0.4 !important;
  background: #b18503;
  border: none;
}

.next-btn.btn:hover {
  background: #b18503;
  border: none;
  opacity: 0.8;
}

.next-btn.btn:first-child:active {
  background: #b18503;
  border: none;
  box-shadow: none;
}

/********* custom select css************/
.auto-complete {
  position: absolute;
  width: 100%;
  max-width: 329px;
  max-height: 242px;
  height: auto;
  top: 50%;
  background: #fff;
  left: 0;
  border-radius: 5px;
  z-index: 9999;
  border: 1px solid #eaebec;
  overflow: auto;
  padding: 5px 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  transform: translate(-50%, -50%);
  left: 50%;
}

.auto-complete li {
  padding: 8px;
  margin: 5px;
  line-height: 1.2;
  color: #a5a5a5;
  cursor: pointer;
}

.select-grid {
  position: relative;
}

.select-section {
  border: 2px solid #0d1949;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  padding-right: 50px;
  height: 62px;
  width: 100%;
  position: relative;
  gap: 15px;
}

.select-section::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: url("../../assets/images/pricing/down-arrow.svg") no-repeat center;
  right: 18px;
}

.select-section h6 {
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  text-transform: capitalize;
  color: #0d1949;
  margin: 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-left: 26px;
}

html.hide-body-content body::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: #181f37;
}

.top-slider-grid .disable-event,
.bottom-slider-grid .disable-event {
  pointer-events: none !important;
}

.top-slide.visited {
  opacity: 1;
}

/* ***************************** Input Form ******************************** */

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.weight-box-for-basic{
  min-height: 62px;
}

.form-input-wrapper {
  position: relative;
  max-width: 231px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.form-input-wrapper input {
  border: 0;
  border-bottom: 2px solid #828282;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #828282;
  caret-color: #828282;
  transition: all 0.3s;
}

.form-input-wrapper input::placeholder {
  font-weight: 500;
  opacity: 0.5;
  font-size: 16px;
}

.form-input-wrapper input:focus {
  border-color: #b18503;
  box-shadow: none;
}

.form-input-wrapper.ratio-length input,
.form-input-wrapper.ratio-width input {
  padding: 0.375rem 30px;
}

.form-input-wrapper.ratio-length::before,
.form-input-wrapper.ratio-width::before {
  position: absolute;
  content: "";
  width: 32px;
  height: 32px;
  top: 10px;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.form-input-wrapper.ratio-length::before {
  background-image: url(../../assets/images/pricing/height.svg);
}

.form-input-wrapper.ratio-width::before {
  background-image: url(../../assets/images/pricing/width.svg);
}

.form-input-wrapper .form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.005em;
  margin: 0;
  color: #6a77a2;
  transition: all 0.3s;
}

.form-input-wrapper input:focus+label {
  color: #b18503;
}

.fade.modal-backdrop.backdrop-input-bg.show {
  background: #181f37;
  opacity: 1;
}



.pi-input-selector .modal-dialog {
  max-width: 329px;
  width: 100%;
  margin: 0 auto;
}

.pi-input-selector .modal-body {
  padding: 0;
}

.input-option-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 597px;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-radius: 10px;
}

.pi-option-elem {
  width: 100%;
  padding: 14px;
  display: flex;
  flex-direction: row;
  gap: 13px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #0d1949;
  border: 1px solid transparent;
  transition: all 0.3s;
}

.step-option-disable {
  color: #a5a5a5 !important;
  cursor: none;
}

.next-price-btn {
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
}

.botton-diamond-data {
  margin-bottom: 10px;
  font-weight: 600;
}

.pi-option-elem:hover {
  border: 1px solid #b18503;
}

.slider-dots-wrapper {
  position: relative;
}

.slider-dots-wrapper .slider-dot-ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  list-style: none;
  padding: 0 30px;
  gap: 16.5px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slider-dots-wrapper .slider-dot-ul .slider-dot {
  background: #ffffff;
  opacity: 0.4;
  width: 9.5px;
  height: 9.5px;
  border-radius: 100%;
}

.slider-dots-wrapper .slider-dot-ul .slider-dot.visited {
  background: #182960;
  opacity: 1;
}

.slider-dots-wrapper .slider-dot-ul .slider-dot.active {
  background: #182960 !important;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
  opacity: 1;
}

.input-option-accord {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 10px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.input-option-accord .accordion-item {
  width: 100%;
  border: 0;
}

.input-option-accord .accordion-header,
.input-option-accord .accordion-header button {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #181f37;
}

.input-option-accord .accordion-header button {
  padding: 10px 27px;
}

.input-option-accord .accordion-header button:focus {
  box-shadow: none;
}

.input-option-accord .accordion-button:not(.collapsed) {
  background: #fff;
  box-shadow: none;
  color: #b18503;
  font-weight: 600;
}

.input-option-accord .accordion-button::after {
  transform: scale(0.6);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
  transform: scale(0.6) rotate(180deg);
}

.undertone-sub-btn {
  position: fixed;
  width: 100%;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 32px;
}

.input-option-accord .accordion-body {
  padding: 10px 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.005em;
  color: #182960;
  min-height: 397px;
}

.undertone-option-wrapper {
  max-height: 690px;
}

/* ****************** Toggle switch ********************* */

.single-pair-checkbox {
  max-width: 188px;
  width: 100%;
  /* height: 48px; */
  height: 63px;
  background-color: #fff;
  position: relative;
  color: #b18503;
  overflow: hidden;
  border: 2px solid rgba(216, 165, 80, 0.4);
  /* border-radius: 6px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.build_price_toggle .single-pair-checkbox {
  min-width: 350px;
  min-height: 54px;
  border-radius: 6px;
}

.build_price_toggle .single-pair-checkbox .single-pair-slide {
  /* width: 270px; */
  width: 350px
}

.build_price_toggle .single-pair-checkbox .single-pair-toggle {
  width: 161.28px;
  /* min-height: 48px; */
  min-height: 44px;
}

.build_price_toggle .single-pair-checkbox .single-pair-slide .single-pair-text {
  text-transform: uppercase;
  /* margin-left: 20px; */
}

.build_price_toggle .single-pair-check:checked+.single-pair-checkbox .single-pair-slide .single-pair-text {
  margin-left: 0px;
}

.build_price_toggle .single-pair-check:checked+.single-pair-checkbox .single-pair-slide .single-pair-toggle {
  /* transform: translateX(126px) !important; */
  transform: translateX(178px) !important;
}

.pair-disabled_bd {
  border: 2px solid #A3A3A3;
}

#checkbox_toggle {
  display: none;
}

.single-pair-checkbox .single-pair-toggle {
  width: 88.5px;
  /* height: 35.56px; */
  height: 49px;
  position: absolute;
  /* border-radius: 8px; */
  left: 4px;
  cursor: pointer;
  background: #b18503;
  transition: 0.4s;
}

.single-pair-checkbox .single-pair-slide {
  width: 177px;
  height: 35.56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.single-pair-checkbox .single-pair-slide .single-pair-text {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: lowercase;
  z-index: 100;
  cursor: pointer;
}

.single-pair-check:checked+.single-pair-checkbox .single-pair-slide .single-pair-toggle {
  transform: translateX(92px);
}

/* .single-pair-check:checked + .single-pair-checkbox .single-pair-slide {
  background-color: #b18503;
  color: #fff;
} */

.single-pair-text.single,
.single-pair-text.pair {
  color: #fff;
}

.top-slider-wrapper.first-slide .slick-list {
  padding: 0 30px !important;
}

.top-slider-wrapper.last-slide .slick-list {
  padding: 0px 30px 0px 210px !important;
}

.bottom-slider-grid .slick-slide.slick-active.slick-current.disable-event {
  pointer-events: unset !important;
}

.pricing-slider {

  height: calc(100vh - 56px);
  /* min-height: 100vh; */
}

.form-input-newwrapper .btn_info_icon,
.form-input-newwrapper .btn_info_icon:active,
.form-input-radiowrapper .btn_info_icon,
.form-input-radiowrapper .btn_info_icon:active {
  background: none !important;
  border: none;
  padding: 0;
}

/* **************************** Summery Card **************************** */

.summery-card-wrapper .pricing-grade h2 {
  /* padding: 24px 0 48px; */
  padding: 24px 0 28px;
}

.summery-card-wrapper .pricing-grade {
  padding: unset;
}

.summery-content-top {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  gap: 12px;
  padding-bottom: 10px;
}

.disable_input {
  border-color: #CED2DE !important;
  color: #CED2DE !important;
}

.disable_input h6 {
  color: #CED2DE !important;
}

.disable_bg {
  background: #CED2DE !important;
}

.disable_border {
  border: 2px solid #FF0000 !important;
}
.disable_pair_border{
  border-color: #D4D4D4 !important;
}
.calculate_price_btn{
  text-transform: none;
}

.calculate_price_btn .btn{
  border-radius: 0px !important;
}

.disable-ratio .shape_radiobtn [type="radio"]:disabled {
  box-shadow: 0 0 0 1px #a3a3a3 !important;
  /* border: 1px solid red; */
}

.form-input-radiowrapper .shape_radiobtn [type="radio"]:checked:disabled {
  background: #828282;
}

.form-input-radiowrapper .shape_radiobtn [type="radio"]:disabled {
  box-shadow: 0 0 0 1px #828282;
}


.disable_inp_bg:disabled {
  background-color: unset !important;
  color: #CED2DE !important;
}
.true_faceup_text{
  font-size: 14px !important;
}

.pair-disabled {
  background: #A3A3A3 !important;

}


@media screen and (max-width:325px) {
  .summery-content-top {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-bottom: 10px;
  }
}

.summery-content-top li {
  display: flex;
  width: 100%;
  padding: 0 14px 0 26px;
  gap: 8px;
  align-items: center;
}

.summery-content-top li h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #182960;
  margin: 0;
  width: 48%;
}

.summery-content-top li span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #182960;
  display: flex;
  align-items: center;
  width: 50%;
}

@media screen and (max-width:991px) {

  .slick-track {
    display: flex;
    align-items: center;
    height: 460px;
  }

  .slick-slide .pricing-grade {
    /* min-height: 480px;    */
    min-height: 400px;
    margin-top: 10px;
  }

  .slick-center .pricing-grade {
    /* min-height: 512px; */
    min-height: 430px;
    margin-top: 0px;
  }
  
}

@media screen and (max-width:492px) and (min-width:400px) {

  .pricing-grade {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    /* min-height: 492px; */
    min-height: 480px;
    padding-top: 37px;
    z-index: 9999;

  }

  .summery-content-top li h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 16px; */
    text-transform: uppercase;
    color: #182960;
    margin: 0;
    width: 48%;
  }

  .summery-content-top li span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 16px; */
    color: #182960;
    display: flex;
    align-items: center;
    width: 50%;
  }

  
}

@media screen and (max-width:400px) {

  .pricing-grade {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 512px;
    padding-top: 37px;
    z-index: 9999;

  }

  .slick-track {
    display: flex;
    align-items: center;
    height: 460px;
  }

  .slick-slide .pricing-grade {
    /* min-height: 480px;    */
    min-height: 400px;
    margin-top: 10px;
  }

  .slick-center .pricing-grade {
    /* min-height: 512px; */
    min-height: 430px;
    margin-top: 0px;
  }

  .summery-content-top li h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 16px; */
    text-transform: uppercase;
    color: #182960;
    margin: 0;
    width: 48%;
  }

  .summery-content-top li span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 16px; */
    color: #182960;
    display: flex;
    align-items: center;
    width: 50%;
  }

  .pricing-grade h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #182960;
    margin: 0;
    padding: 62px 0px;
  }

  .pricing-grade .ratio-pd {
    padding: 54px;
  }
}

.summery-content-bottom {
  padding: 12px 0;
  background: #181f37;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.summery-content-bottom li h6,
.summery-content-bottom li span {
  color: #fff;
}

@media only screen and (max-height: 668px) and (orientation: portrait) {
  .pricing-slider {
    /* min-height: 100vh !important;
      height: unset !important; */
    height: calc(100vh - 64px) !important;
  }
}

@media only screen and (max-height: 740px) and (orientation: portrait) {
  .pricing-slider {
    /* min-height: 100vh !important;
        height: unset !important; */
    height: calc(100vh - 64px) !important;
  }
}

@media only screen and (max-height: 768px) and (orientation: landscape) {
  .pricing-slider {
    /* min-height: 100vh !important;
          height: unset !important; */
    height: calc(100vh - 64px) !important;
  }
}

@media only screen and (max-height: 653px) and (orientation: landscape) {
  .pricing-slider {
    /* min-height: 100vh !important;
    height: unset !important; */
    height: calc(100vh - 64px) !important;
  }
}


@media only screen and (max-height: 540px) and (orientation: landscape) {
  .pricing-slider {
    /* min-height: 100vh !important;
    height: unset !important; */

    height: calc(100vh - 64px) !important;
  }
}

@media only screen and (max-height: 375px) and (orientation: landscape) {
  .pricing-slider {
    /* min-height: 100vh !important;
    height: unset !important; */
    height: calc(100vh - 64px) !important;
  }
}

@media only screen and (max-height: 414px) and (orientation: landscape) {
  .pricing-slider {
    /* min-height: 100vh !important;
      height: unset !important; */
    height: calc(100vh - 64px) !important;
  }
}

.dark-bg-grid .pricing-grade h2,
.dark-bg-grid .select-section h6 {
  color: white;
}

.dark-bg-grid .select-section {
  border: 1px solid white;
}

.polish-skip-text {
  position: absolute;
  bottom: 10px;
  font-size: 14px;
  color: #b18503;
  text-decoration: underline;
}

/* ***************************************************************************** */

.accordian_outer {
  width: 100%;
}

.accordian_outer .pi-option-elem {
  justify-content: space-between;
}

.sub-option-wrapper {
  padding: 0px 30px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  background: #f0f0f0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.open_wrapper {
  /* padding: 10px 30px; */
  max-height: 180px;
  transition: max-height .5s ease;
}



.pi-sub-option-elem:nth-child(1) {
  padding-top: 10px;
}

.pi-sub-option-elem:nth-last-child(1) {
  padding-bottom: 10px;
  border: none;
}


.accordian_outer .pi-sub-option-elem {
  font-size: 14px;
}

.accordian_outer .active_pioption img {
  /* transition: transform 2s; */
  rotate: 180deg;
}

.accordian_outer .active_pioption,
.accordian_outer .active_pioption:hover {
  background: #f0f0f0;

  border-bottom: 1px solid #e0dddd !important;
}

.accordian_outer .active_pioption:hover {
  border: none;
}



.ratio_item {
  width: 80px !important;
  height: 80px !important;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.ratio_item:hover {
  border: 1px solid #BF8F02;
  ;
}

.active-ratio-item {
  border: 1px solid #BF8F02;
  ;
}

.ratio_img_top {
  height: 40px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 2px;
}

.ratio_para {
  text-align: center;
  font-family: Montserrat;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 22px; */
  text-transform: uppercase;
}

.sub_drop {
  /* background-color: #f0f0f0; */
  border: none !important;
  /* color: #939CBC !important; */
  border-radius: 0 !important;
  justify-content: flex-start !important;
  padding-left: 30px !important;
}

@media screen and (max-width:480px) {
  .ratio_container_pd {
    padding: 0px 30px;
  }
  .bottom-slider-grid {
    height: calc(100vh - 180px);
   }
   .pricing-slider {
    height: unset;
   }

}

@media screen and (max-width:380px) {
  .ratio_container_pd {
    padding: 0px 10px;
  }

}


.center-slider .slick-slide,
.center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
  transform: scale(0.8, 0.8);
  transition: all 0.4s ease-in-out;
}