.idu-section-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 5px;
  /* min-height: 470px; */
}

.idu-top-section{
  min-height: 100px;
}

.idu-top-section h5 {
  font-family: Cinzel;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 0px;
}

.idu-top-section p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1.5%;
  text-align: center;
  max-width: 520px;
  margin: auto;
}

.idu-main-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 375px;
}

.idu-top-section p span {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1.5%;
  text-align: center;
  color: #a8a8a8;
  text-decoration: underline;
  cursor: pointer;
}

.idu-image-box {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.idu-image {
  width: 110px;
  height: 110px;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0.5px 3.7px 0px #0000004d;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.active-image {
  box-shadow: 0px 0.5px 3.7px 0px #000000;
}

.idu-image img {
  object-fit: contain;
  position: absolute;
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.idu-image-container p {
  text-align: center;
  display: flex;
  background-color: #ffee82;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin: 15px auto 0px;
  color: #5c6da4;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.idu-text-box {
  max-width: 397px;
  /* min-height: 108px; */

  margin: 0px auto 0px;
}

.idu-text-box p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.21px;
  text-align: center;
}

.idu-footer-section {
  /* padding-bottom: 0px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 80px); */
}

.idu-bottom-section {
  min-height: 50px;
}
.idu-bottom-section h6 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.5%;
  text-align: center;
}

.idu-footer-section p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1.5%;
  text-align: center;
  display: flex;
  justify-content: center;
  text-wrap: nowrap;
}

.idu-undertone-arrow-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: -30px;
  min-height: 50px;
}

.idu-undertone-arrow-section .undertone-arrow-text {
  width: 40px;
}
.idu-undertone-arrow-section .undertone-arrow-text p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.undertone-arrow img {
  width: 370px;
}

.undertone-arrow p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 10px;
  line-height: 29px;
  letter-spacing: 0px;
  margin-bottom: -14px;
  text-align: center;
}

.back-button-section {
  max-width: 92px;
  margin: auto;
  position: relative;
  
}


.back-button-section .back-contain-btn  {
  position: absolute;
  top: -7px;
  left: -26px;
  transform: rotate(180deg);
  }

  .back-button-section p {
    color: #b18503;
    text-align: center;
    /* position: absolute;
    top: -5px;
    left: -10px; */
    }

.back-button-section .btn {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 8.39px;
  letter-spacing: 0%;
  text-align: center;
}
