.result-screen {
  padding: 33px 33px 20px;
  background: #b2bccd;
}
.result-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 50px 20px;
  margin-bottom: 34px;
}
.result-body-heading h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #182960;
  margin: 0;
}

.result-body-heading h2 span {
  font-size: 16px;
}

.extra-margin-body {
  margin: 50px 0px;
}

/* .extra-margin {
    margin: 5% 0px !important;
} */

.result-body-heading h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #182960;
  margin: 0;
  white-space: nowrap;
  padding-top: 10px;
}

.result-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 22px;
  padding-block: 18px;
}

.result-body img {
  height: 150px;
  width: 150px;
}

.result-price h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #182960;
  margin: 0;
  white-space: nowrap;
}

.result-price span {
  font-size: 16px;
}

.result-job p {
  font-size: 10px;
}
.result-price p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #182960;
  margin: 0;
}

.diamond-detail {
  font-size: 14px !important;
  color: #b18503 !important;
  /* position: absolute;
    bottom: 20%; */
}
.diamond-info {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  line-height: 32px !important;
}

.result-screen .next-btn-grid {
  padding: 0;
}

.card-screen-Height {
  height: calc(100vh - 200px) !important;
}

/* for desktop result */

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.loaderContainer h6 {
  color: #182960;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0.12px;
}

.loaderContainer {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.12px;
}

.desktop-result-screen-container{
  min-height: calc(100vh - 178px);
}

.desktop-result-screen {
  background: #fbfcff;
 
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: 820px;
  /* padding-bottom: 50px; */

}

.result-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;

    min-height: calc(100vh - 120px);
    padding: 20px 0px;
}

.result-loading .desktop-result-body {
  background-color: unset;
  box-shadow: unset;
}

.desktop-result-screen .next-btn-grid {
  max-width: 562.809px;
  width: 100%;
  gap: 20px;
  padding: 44px 0px 20px 0px;
}

.adu-btn {
  background: transparent !important;
  border: 2px solid #b18503 !important;
  color: #b18503 !important;
}

.desktop-result-screen .next-btn {
  max-width: 364px;
  width: 100%;
  height: 54px;
}

.desktop-result-body {
  max-width: 562.809px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 40px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 702px;
}

.desktop-result-top,
.desktop-result-last,
.desktop-result-mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktop-result-top p {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
}

.desktop-result-top span {
  color: var(--BLUE, #182960);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.064px;
  text-align: center;
}
.desktop-result-mid {
  padding-bottom: 8px;
}

.desktop-result-mid p {
  text-align: center;
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin: 0px;
  padding-bottom: 10px;
}

.desktop-result-mid .diamond_result_img {
  height: 179px;
  width: 179px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-result-mid .diamond_result_img_pear{
  margin-top: 1.5rem;
}

.desktop-result-mid .diamond_result_img img {
  height: 100%;
  width: 100%;
}

.desktop-result-last p {
  color: var(--BLUE, #182960);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
  letter-spacing: 0.08px;
  padding-bottom: 15px;
}

.desktop-result-last h6 span {
  font-size: 20px;
  font-weight: 700;
  color: #182960;
}

.desktop-result-last h6 {
  color: var(--BLUE, #182960);
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 68.75% */
  text-transform: uppercase;
}

.desktop-result-last span {
  color: #000;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px; /* 168.75% */
  letter-spacing: 0.16px;
  text-align: center;
  max-width: 316px;
}

.desktop-result-body .last-span-text {
  font-size: 12px;
  font-weight: 300;
  line-height: 23px;
  text-align: center;
  margin-top: 10px;
}

.DiamondRsult h6 {
  color: var(--BLUE, #182960);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
  letter-spacing: 0.08px;
}
.DiamondRsult {
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.DiamondRsult p {
  color: var(--BLUE, #182960);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.064px;
  max-width: 345px;
  width: 100%;
}

.diamond-detail_result {
  position: absolute;
  bottom: 0px;
}
.Idu_popup {
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* padding: 0px 15px; */
}
.Idu_popup_container .modal-content {
  max-width: 467.93px;
  width: 100%;
  padding: 15px 15px;
}
.Idu_popup_container .modal-dialog-centered {
  justify-content: center;
}

.Idu_popup_container .modal-header,
.Idu_popup_container .modal-footer {
  border: none;
  justify-content: center;
}

.Idu_popup_container .modal-header h3 {
  font-size: 24px;
  text-align: center;
  color: #182960;
}

.Idu_popup_container .modal-header span {
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
}

.adjust-idu-model .modal-header span {
  top: 2%;
}

.adjust-idu-model .modal-header h3 {
  font-size: 28px;
  font-weight: 400;
}

.Idu_popup_container .modal-header span img {
  height: 18px;
  width: 40px;
}

.Idu_popup .form-input-radiowrapper {
  max-width: 100%;
  justify-content: space-between;
}

.Idu_popup .shapes_container {
  justify-content: center;
}

.true_face_up_rc p {
  font-size: 12px;
}

.desktop-result-top .report-with-gia {
  color: #979797;
  font-size: 14px;
  padding-bottom: 25px;
  margin-top: -10px;
}

.download-pdf-wrapper {
  position: absolute;
  top: -6000px;
  left: -6000px;
}

.iduToggle .single-pair-checkbox .single-pair-slide {
  width: 200px;
}
.iduToggle .single-pair-checkbox .single-pair-toggle {
  max-width: 100px !important;
}

.Idu_Footer p {
  font-size: 18px;
  color: #b18503;
  text-decoration: underline;
  cursor: pointer;
}

.Idu_popupmob_container {
  display: none;
}
.adu-btn-mob {
  display: none;
}
.adu-btn-desk {
  display: block;
}
.next-btn-mob {
  display: none !important;
}

.form-input-newtoggle .single-pair-checkbox {
  max-width: unset;
  height: 61px;
  /* border-radius: 9px; */
  border-color: #6a77a2;
  color: #182960;
}

.form-input-newtoggle .single-pair-checkbox .single-pair-toggle {
  background: #182960;
  /* border-radius: 8px; */
  max-width: 83px;
  width: 100%;
  height: 48px;
}

h6.report-text-mobile {
  display: none;
}


/* adjust IDU */
.adjust-idu-container{
  
}
.adjust-idu-model .modal-content{
  min-height: 920px;
  min-width:  1191px

}

.adjust-container{
  display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      min-height: 550px;
      gap: 80px;
}
.adjust-container .shapes_container {
  justify-content: center;
}

.adjust-faceup-container{
  display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.adjust-faceup-container p, 
.adjust-pairType-container p {
  display: inline !important;
  position: relative;
}

.adjust-faceup-container p .btn, 
.adjust-pairType-container p .btn {
  background: transparent;
  border: none;
  position: absolute;
  left: -50px;
  top: 10px;
}

.adjust-faceup-container p .btn:active, .adjust-pairType-container p .btn:active {
  background: transparent;
  border: none;
}


.adjust-faceup-container h5 {
  font-family: Cinzel;
font-weight: 400;
font-size: 40px;
line-height: 50px;
letter-spacing: 0%;
text-align: center;
}
.adjust-pairType-container{
  display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.adjust-pairType-container h5 {
  font-family: Cinzel;
font-weight: 400;
font-size: 40px;
line-height: 50px;
letter-spacing: 0%;
text-align: center;
}


/* adjust IDU */
@media only screen and (max-height: 653px) and (orientation: landscape) {
  .card-screen-Height {
    min-height: 100vh;
  }
}

@media only screen and (max-height: 540px) and (orientation: landscape) {
  .card-screen-Height {
    min-height: 100vh;
  }
}

@media only screen and (max-height: 414px) and (orientation: landscape) {
  .card-screen-Height {
    min-height: 100vh;
  }
}

@media only screen and (max-height: 375px) and (orientation: landscape) {
  .card-screen-Height {
    min-height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .desktop-result-screen {
    padding: 30px 25px 20px;
  }
  .desktop-result-screen .next-btn {
    max-width: 100%;
  }

  .adu-btn-mob {
    display: block;
  }

  .adu-btn-desk {
    display: none;
  }
.next-btn-desk {
  display: none !important;
}

.next-btn-mob {
  display: flex !important;
}

  .desktop-result-body {
    /* min-height:unset; */
    padding: 50px 20px 30px;
    gap: 14px;
  }

  .diamond-info {
    margin: 0px 10px;
    line-height: 27px !important;
  }

  .desktop-result-top p,
  .desktop-result-mid p,
  .desktop-result-last p,
  .desktop-result-last h6 span {
    font-size: 16px;
  }
  .desktop-result-last h6 {
    font-size: 20px;
  }

  .desktop-result-top span,
  .desktop-result-last span {
    font-size: 12px;
    line-height: 20px;
  }

  .desktop-result-mid .diamond_result_img {
    height: 110px;
    width: 110px;
  }
  .desktop-result-screen .next-btn-grid {
    padding: 50px 0px 0px 0px;
    flex-direction: column;
  }

  .Idu_popupmob_container {
    position: fixed;
    bottom: -10px;
    width: 100%;
    height: 0%;
    background: #4343433d;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1;
  }

  a_animation {
    height: 100%;
  }
  .Idu_popupmob_container .Idu_popup_container {
    background: white;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    transform: translateY(100%);
    transition: all 1s ease-out;
  }

  .Idu_popupmob_container .Idu_popup_container_animation {
    transform: translateY(0%);
    transition: all 1s ease-out;
  }

  .Idu_popupmob_container .mob_Idu_popup_container {
    transform: translateY(0%);
  }
  .Idu_popupmob_container h3 {
    text-align: center;
    color: #182960;
  }

  .Idu_popupmob_container .Idu_Footer {
    text-align: center;
    padding: 30px 0px 10px;
  }
  .Idu_desktop_outer {
    display: none !important;
  }

  .report-text{
    display: none;
  }

  h6.report-text-mobile {
    display:block;
    font-size: 12px;
    font-weight: 400;
  }

  .report-text-mobile{
    margin-bottom: -8px;
  }
}

@media screen and (max-width: 480px) {
  .Idu_popup_container .modal-dialog-centered {
    align-items: flex-end;
    margin: 4px -2px 4px -2px;
    transform: translate(0, 2%) !important ;
  }

  .Idu_popup_container .modal-header span {
    display: none;
  }
  .Idu_popup_container .modal-body {
    padding: 0px;
  }
  .Idu_popup .shapes_container {
    max-width: 180px;
    gap: 20px;
  }

  .Idu_popup_container .modal-content {
    padding: 15px 10px;
  }

  .Idu_popup_container .modal-title {
    margin-bottom: 10px;
  }

  .Idu_popup_container .modal-footer {
    padding-top: 20px;
  }
  .Idu_popup p {
    font-size: 12px;
  }
}


.export_text{
    text-decoration: underline;
    /* margin-top:5px ; */
    font-weight: 300;
    color: #B18503;
    font-size: 16px;
    list-style: 22px;
    cursor: pointer;
    margin-bottom: -10px;
    
}
.report-text{
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
}


/* .report-text-mobile{
  display: none;
 
} */
/* pdf css */
.pdfOuter {
  /* width: 595px; */
  width: 820px;
  height: 1060px;
  /* background-color: #b3bccd; */
  background-color:  #FBFCFF;

  gap: 60px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0px 40px;
  /* / align-items: center; / */
  /* justify-content: space-between; */
}

.pdfOuter h3 {
  color: #001534;
  text-align: center;
  font-family: Cinzel;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: 0.32px;
  text-transform: lowercase;
  margin: 0;
}

.pdfOuter h3 span {
    font-size: 13px;
}

.pdfOuter p {
  color: #000;
  margin: 0;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
}

.pdf_card {
  /* width: 472.08px;
height: 427.504px; */
  width: 572.08px;
  padding: 20px 0px;
  /* height: 568.504px; */
  margin: 0 auto;
  border-radius: 15px;
  background: #fff;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.pdf_card p {
  color: #000;

  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
}
.giaPdfNumber {
  text-align: center;
  width: 100%;
  line-height: 22px;
}

.card_diamond {
  display: flex;
  width: 138.213px;
  height: 138.213px;
  padding: 2.648px 24.084px 10.751px 24.345px;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
  margin: auto;
}


.card_detail span {
  /* width: 374.879px; */
  width: 454.879px;
  margin: auto;
}
.card_detail h6 {
    
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-top: 15px;
}

.card_detail span {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  display: flex;
  /* background-color: #fff; */
}


.card_detail h4 {
    display: flex;
    width: 200px;
    margin: auto;
  text-align: center;
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.24px;
 
}

.card_detail h4 span{
    margin-top: 5px;
    /* font-size: 14px; */
    font-weight: 600;
}



.card_detail_data_price{
background: rgb(19, 18, 18);
display: flex;
justify-content: center !important; 
 align-items: center;
 
}


.card_detail h4 span{
    font-size: 12px;
    margin-top: 14px;
    margin-left: 5px;
    
}

.card_detail p {
  line-height: 25px;
  text-transform: capitalize;
  margin-top: 25px;
}



.card_detail_data {
    font-size: 11px !important;
  padding-top: 20px;
}

.price_small_text_pc{
    font-size: 16px;
    margin-top: 12px;
    
}

  

.pdf-footer-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pdf-footer-logo span {
  color: #001534;
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0.13px;
  text-transform: lowercase;
  margin: 0px;
}

.pdf-footer-logo svg {
  width: 122.73px;
  height: 122.5px;
}


.pc_small_txt{
    display: inline;
    font-size: 10px;
}

.idu-popup-mode-text {
  font-size: 16px;
  line-height: 2px;
  text-align: center;
}