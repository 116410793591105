@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 0px;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f7f7f7; */
  /* background-color: #b2bccd; */
  background-color: #FBFCFF;
}
.disclaimer-active {
  /* background: #181f38; */
  background: #ffffff;
}
button {
  font-family: "Montserrat", sans-serif !important;
}

span.price-intelligence-layout-text {
  display: none;
}

.error-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ff0000;
}
.desktop-error-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ff0000;
}

.form-input-wrapper.error-msg input,
.form-input-wrapper.error-msg input:focus,
.form-input-wrapper.error-msg input:focus + label {
  color: #ff0000;
  caret-color: #ff0000;
  border-color: #ff0000;
}

.form-input-wrapper.error-msg .form-label {
  color: #ff0000;
}
.menu-toggle-desk {
  display: none !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.display-disclaimer {
display: none;
}

.pricing-entrence-banner-img {
display: none;
}
.pricing-entrence-content-mob {
  display: flex;
}

.start-pricing-mob-content {
display: none;
}
.start-pricing-desktop-content { 
  /* display: none; */
}


.start-pricing-mob-content .build_price_toggle {
  gap:40px !important;
}

.new-start-pricing .build_price_toggle {
gap: 30px ;
}

.rotate-180{
  transform: rotate(180deg);
}

.diamond-full-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

@media screen and (min-width: 1225px) {
  .start-pricing-content, .pricing-entrence-content {
    height: calc(100vh - 64px);
  }
  .start-pricing-mob-content {
    /* display: none; */
  }
  .start-pricing-desktop-content {
    display: block;
  }
  .pricing-entrence-banner-top {
    margin-top: 250px;
  }

  .pricing-entrence-banner-img .price-header-text{
    margin-top: 190px;
  }

  .start-pricing-desktop-content .pricing-entrence-banner-img1 h1{
    color: #fff;
    font-family: Cinzel, serif;
    font-size: 44px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 40px;
    margin: 0;
    padding-bottom: 40px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    z-index: 9;
  }

  .start-pricing-desktop-content .pricing-entrence-banner-img1 h3{ 
    color: #fff;
    font-size: 20px;
    letter-spacing: 2%;
    line-height: 22px;
  }

  .start-pricing-desktop-content .build_price_toggle {
    gap:35px;
  }

  .display-disclaimer {
display: block;
  }
  .display-mob-disclaimer {
    display: none;
  }

  .pricing-userlogin-content,.pricing-entrence-button{ 
    max-width: 650px;
    width: 100%;
    margin: auto !important;
  }

  .pricing-entrence-content-img { 
    background-image: url("./assets/images/pricing/banner_desktop.png");
    max-height: calc(100vh - 345px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    
  }


  .betaPrice p{
    margin-bottom: 2px;
  }
  .betaGia p{
    margin-left: 12px;
  }
  .pricing-entrence-content-mob {
    display: none;
  }

  .pricing-entrence-banner-img {
    max-height: calc(100vh - 64px);
    height: 100%;
    background-image: url("./assets/images/pricing/banner_desktop.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pricing-entrence-banner-img:before {
    content: " ";
    position: absolute;
    bottom: -2px;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(13, 25, 73, 0.19) 11.55%, rgba(13, 25, 73, 0.94) 48.73%, #181F37 99.68%);
}
.pricing-entrence-content-img {
  display: none;
}
  /* .pricing-entrence-content-img:before {
    max-height: calc(100vh - 345px);
  } */

  .menu-toggle-mob {
    display: none !important;
  }

  .menu-toggle-desk {
    display: flex !important;
    width: 118px !important;
    justify-content: flex-end !important;
  }

  .custom_box_home [type="checkbox"] + label {
    color: white;
  }

  .pricing-entrence-content-img h3 {
    padding-bottom: 90px;
  }

  .pricing-entrence-content-img h3 {
    color: #fff;
    z-index: 2;
    margin: 0;
    padding-bottom: 30px;
  }

  .checkbox_desk {
    padding-top: 15px;
  }

  .desktop_pricing {
    min-height: calc(100vh - 56px);
    padding: 40px 80px;
    background-color: #FBFCFF;

    display: flex;
    justify-content: center;
    align-items:center ;

  }

  .desktop_pricing_inner {
    border-radius: 10px;
    background:  #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    /* height: 100%; */
    padding: 40px 40px 0px;
    width: 100%;
    height: 100%;
    min-height: 820px;
    position: relative;
}

.desktop_pricing_grade {
  width:100%;
  display: flex;
  height: 100%;
  /* padding: 40px; */
}

.desktop_pricing_left {
width: 50%;
}
.desktop_pricing_right {
  width: 50%;
}

.form-input-newwrapper {
  max-width: 400px;
}
.form-input-newwrapper p {
  max-width: 134px;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 2px;
  border-bottom: 0.5px solid white;
  padding-bottom: 10px;
  cursor: pointer;
}

.sub-option-wrapper p {
  font-size: 14px;
  text-wrap: nowrap;
  /* border-bottom: 0.5px solid #1f326c; */
}

.newSelect-grid {
  /* width: 230.962px; */
  height: 60.001px;
  border: 2px solid #6A77A2;
  color: var(--placeholder, #939CBC);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
}

.newSelect-grid option {
  /* text-align: left; */
  /* margin: 40px;
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
}

.newSelect-grid>option:hover { 
  background: #000 !important;
}
  /* .price-intelligence-layout {
    display: none;
  } */

  /* span.price-intelligence-layout-text {
    height: 100vh;
    width: 50%;
    text-align: center;
    margin: auto;
    font-family: "Cinzel";
    font-style: normal;
    display: flex !important;
    font-weight: 400;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    text-transform: uppercase;
    color: #0d1949;
    align-items: center;
    justify-content: center;
  } */

  .form_select_drop {
    width: 220px;
    border: 2px solid #1F326C;
    color: var(--placeholder, #1F326C);
    text-align: center;
    /* border-radius: .375rem; */
    padding: .375rem 2.25rem .375rem .75rem;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* text-wrap: nowrap; */
  }
  

  .form_select_drop::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: url("./assets/images/pricing/down-arrow.svg") no-repeat center;
    right: 18px;
  }
  .form_select_dropRotate.sub_drop  {
    background: #f0f0f0;
    border-bottom: 1px solid #e0dddd !important;
  }

  .form_select_dropRotate::after {
     rotate: 180deg;
  }
  .form_select_container {
    position: relative;
  }

  .form_select_dropdownBox {
    min-height: 50px;
    position: absolute;
    background: white;
    z-index: 9;
    max-width: 220px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #6A77A2;
    color: var(--placeholder, #939CBC);
    border-radius: .375rem;
    top: 65px;
    display: none;
}

.fluorescence_select_dropdownBox {
  top: -293px !important;
}

.form_select_dropdownBoxBlock {
  display: block;
  max-height: 321px;
  overflow-y: auto;
}
.floureceboxBlock {
/* background-color: #f0f0f0;; */
}

.form_select_dropdownBoxBlock::-webkit-scrollbar {
   width:   7px;
}

.form_select_dropdownBoxBlock::-webkit-scrollbar-thumb {
  background: #1F326C;
  border-radius: 7px;
}

.form_select_dropdownBoxBlock::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 7px;
}





.form_select_dropItem:hover {
  background: #1F326C;
  color: white;
}

.form_select_dropdownBox h6{
  font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
    max-width: 220px;
    width: 100%;
    height: 45.287px;
    padding: .375rem 2.25rem .375rem .75rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #182960 ;
}
.form_select_dropdownBox .selectedText {
  border-radius: 5px;
  background: #1F326C;
  color: white;
}
}
#accountSettingHeader .accordion-button::after {
 opacity: 0; /* Hide the arrow icon for Accordion Item #2 */
}