.info-modal .back-modal-body p {
    padding: 19px 0px 50px 0px;
}
.info-modal.fade {
    /* background: #181F37; */
}
.adjust-idu-modal.fade {
    background: #181f3770;
}

.info-modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.info-modal-closing {
    opacity: 0;
    transform: scale(0.9); /* Shrinks before disappearing */
    transition: opacity 0.3s ease, transform 0.3s ease;
}
