.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  /* background: #181f37; */
  
  /* opacity: 0.9; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 84px;
  flex-direction: column;
  z-index: 9999;
}
.loader p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  margin: 0;
}

.history-loader {
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 84px;
}